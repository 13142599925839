import * as React from "react"
import {
    NewAdminIcon,
    NewGroupsIcon,
    NewHomeIcon,
    NewLeadersIcon,
    NewMembersIcon, NewMoreIcon,
    NewNotificationsIcon, NewProfileIcon, NewSearchIcon
} from "../components/icons";
import styled from "styled-components";
import {useState} from "react";

const icon_map = {
    'home': <NewHomeIcon/>,
    'members': <NewMembersIcon/>,
    'search': <NewSearchIcon/>,
    'groups': <NewGroupsIcon/>,
    'profile': <NewProfileIcon/>,
    'more': <NewMoreIcon/>,
    'leaders': <NewLeadersIcon/>,
    'admin': <NewAdminIcon/>,
    'notifications': <NewNotificationsIcon/>,
};

const colors = [
    "#3c81f6",
    "#47573D",
    "#FCCA46",
    "#FE7F2D",
    "#2E294E",
    "#69385C",
    "#FB3640",
    "#468C98",
];

const IconWrapper = styled.div`
  > svg .icb2 {
    fill: ${props => props.active ? props.color : "#4b5563"};
    opacity: ${props => props.active ? 0.2 : 0.075};
  }

  > svg .icb1 {
    fill: ${props => props.active ? props.color : "#4b5563"};
  }
`;

function MenuItem({active, color="#3c81f6", icon = 'home'}) {
    return <div className="relative group cursor-pointer" style={{height: '60px'}}>
        <div className="flex items-center h-full justify-center">
            <IconWrapper color={color} active={active}
                         className={`udk-menuicon ${active ? "" : "group-hover:text-gray-800 text-gray-600"} `}>
                {icon_map[icon]}
            </IconWrapper>
        </div>
        <div
            className={`${active ? "" : "group-hover:bg-gray-200"} rounded absolute left-1 right-1 top-0 sm:top-auto sm:bottom-0`}
            style={{height: '3px',backgroundColor:active?color:""}}/>
    </div>
}

function SearchBar() {
    return <div className=" flex items-center h-full">
        <div className="bg-gray-100 flex justify-start items-center flex-grow rounded-lg border bg-gray-300"
             style={{height: '40px'}}>
            Search
        </div>
    </div>
}

function CommunityLogo({color}) {
    return <div className="">
        <div className="p-3">
            <div className="rounded-md" style={{backgroundColor:color,height: '36px', width: '36px'}}>

            </div>
        </div>
    </div>
}

function Avatar() {
    return <div>
        <div className="p-3">
            <div className="bg-gray-200 rounded-2xl" style={{height: '36px', width: '36px'}}>

            </div>
        </div>
    </div>
}

function DesktopMenu({color}) {
    return <div className="hidden lg:grid udk-mm-desktop udk-mm-menugrid">
        <MenuItem color={color} active/>
        <MenuItem color={color} icon="notifications"/>
        <div className="md:grid lg:hidden udk-mm-menugrid lg:col-span-3">
            <MenuItem color={color} icon="more"/>
        </div>
        <div className="md:hidden lg:grid udk-mm-menugrid lg:col-span-4">
            <MenuItem color={color} icon="members"/>
            <MenuItem color={color} icon="groups"/>
            <MenuItem color={color} icon="leaders"/>
            <MenuItem color={color} icon="admin"/>
        </div>
    </div>
}

function TabletMenu({color}) {
    return <div className="hidden sm:grid lg:hidden udk-mm-tablet udk-mm-menugrid">
        <MenuItem color={color}/>
        <MenuItem color={color} active icon="notifications"/>
        <MenuItem color={color} icon="more"/>
    </div>
}

function MobileMenu({color}) {
    return <div className="grid fixed bottom-0 right-0 left-0 bg-white sm:hidden udk-mm-mobile udk-mm-menugrid">
        <MenuItem icon="home" color={color} active />
        <MenuItem icon="notifications" color={color}/>
        <MenuItem icon="search" color={color}/>
        <MenuItem icon="profile" color={color}/>
        <MenuItem icon="more" color={color}/>
    </div>
}

function Menu({color}) {

    return <div className="udk-mainmenu">
        <div className="flex justify-start">
            <CommunityLogo color={color} />
        </div>
        <div className="udk-mainmenu-nav">
            <div>
                <DesktopMenu color={color} />
                <TabletMenu color={color}/>
            </div>
            <div>
                <SearchBar color={color}/>
            </div>
        </div>
        <div className="flex justify-end">
            <Avatar/>
        </div>
    </div>
}

function Body({children}) {
    return <div className="udk-body sm:pt-8 mx-auto udk-appcontentwidth">
        {children}
    </div>
}

function LayoutStandard({children}) {
    return <div className="udk-layoutstandard">
        {children}
    </div>
}

function LayoutComplex({children}) {
    return <div className="udk-layoutcomplex bg-green-100">
        {children}
    </div>
}

export default function DemoPage() {
    const [i,inc] = useState(0);
    const c = colors[i];
    const l = i % 2 === 0 ? 'complex' : 'standard';
    return (
        <div onClick={()=>{
            if(i+1===colors.length) {
                inc(0);
            } else {
                inc(i+1);
            }
        }} className="udk-appframe">
            <Menu color={c} />
            <Body>

                {l==='standard'&&<LayoutStandard>
                    <div className="bg-green-100">standard layout</div>
                    <div className="bg-blue-100">2</div>
                </LayoutStandard>}
                {l==='complex'&&<LayoutComplex>
                    <div className="bg-green-100">1</div>
                    <div className="bg-blue-100">complex layout</div>
                </LayoutComplex>}
            </Body>
            <MobileMenu color={c} />
        </div>
    )
};